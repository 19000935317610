import React from 'react'

import SimpleHead from 'components/head/SimpleHead'
import pageData from 'data/pages/Contents/download.json'
import DownloadLayout from 'templates/download'

export default function DownloadPage({ location }) {
  const twitterEvents = {
    android: {
      twe: 'tw-o0arj-osa3e', // Twitter event.
      ga: ['Downloads', 'Android_XPage', 'Download_XPage'], // Google Analytics.
      utmSource: 'twitterads',
      utmCampaign: 'x-twitter-page',
      utmContent: 'x-download-page',
    },
    ios: {
      twe: 'tw-o0arj-os62n', // Twitter event.
      ga: ['Downloads', 'iOS_XPage', 'Download_XPage'], // Google Analytics.
      ct: 'twitterads',
    },
    desktop: {
      twe: 'tw-o0arj-ot6zm', // Twitter event.
      ga: ['Downloads', 'Desktop_XPage', 'Download_XPage'], // Google Analytics.
    },
    browser: {
      twe: 'tw-o0arj-ot6zn', // Twitter event.
      ga: ['Downloads', 'Browser_XPage', 'Download_XPage'], // Google Analytics.
      utmContent: 'x-download-page',
    },
    total: {
      twe: 'tw-o0arj-ot70l', // Twitter event.
    },
  }

  return (
    <DownloadLayout
      location={location}
      pageData={pageData}
      pathname="en"
      enableSubscribeNotice={false}
      twitterEvents={twitterEvents}
      noindex
    />
  )
}

export function Head() {
  const { title, description, keywords } = pageData

  return (
    <SimpleHead
      path="x-twitter"
      title={title}
      description={description}
      keywords={keywords}
      noindex
    />
  )
}
